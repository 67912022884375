<template>
	<div v-if="getFieldsActive">
		<b-row>
			<!-- Pagination -->
			<b-col cols="auto">
				<BaseTablePagination
					@input="currentPage = $event"
					:currentPage="currentPage"
					:rowsPerPage="rowsPerPage"
					:totalRows="items(isShow).length"
				/>
			</b-col>
			<!-- Speech Recognition -->
			<b-col cols="auto">
				<BaseSpeechInput :groups="customGroupsandUndecided" @input="addSelectedRefsToGroup" />
			</b-col>
			<!-- Filter -->
			<b-col>
				<ReferenceTableFilter
					@isFiltering="isFiltering = $event"
					@filterText="filterText = $event"
					@change="filteredRefIds = $event"
				/>
			</b-col>
		</b-row>
		<!-- Loading -->
		<div v-if="isFiltering" class="m-3 d-flex align-items-center">
			<strong>Loading...</strong>
			<b-spinner class="ml-auto"></b-spinner>
		</div>
		<!-- Table -->
		<div style="background-color: rgb(245, 245, 245);">
			<b-table
				:sticky-header="tableHeight"
				:per-page="rowsPerPage"
				:current-page="currentPage"
				ref="table"
				id="table"
				class="light-table"
				style="text-align: left;"
				bordered
				selectable
				select-mode="range"
				@row-selected="onSelectRow"
				@keyup.native="keyPress"
				@row-dblclicked="editReference($event)"
				:items="items(isShow)"
				:fields="getFieldsActive"
				:tbody-tr-attr="rowAttr"
				primary-key="id"
				head-variant="light"
				:busy="tableBusy"
			>
				<template v-slot:cell(group)="data">
					{{
						data.item.group && customGroupsAsMap.get(data.item.group)?
							customGroupsAsMap.get(data.item.group).name
							: "None"
					}}
					<b-form-select
						@change="addRefsToGroup($event, [data.item])"
						:value="data.item.group"
						:options="customGroupsandUndecided"
						value-field="key"
						text-field="name">
					</b-form-select>
				</template>

				<template v-slot:cell(title)="data">
					<HighlightText :text="data.value" :searchStrings="[filterText]" />
				</template>

				<template v-slot:cell(authors)="data">
					{{ formatAuthors(data.value) }}
				</template>

				<template v-slot:cell(abstract)="data">
					<b-button size="sm" @click="data.toggleDetails" class="mr-2">
						{{ data.detailsShowing ? "Hide" : "Show" }} Abstract
					</b-button>
				</template>

				<template v-slot:row-details="data">
					<b-card bg-variant="secondary" text-variant="white">
						<HighlightText :text="data.item.abstract" :searchStrings="[filterText]" />
					</b-card>
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';

import HighlightText from './HighlightText.vue';
import ReferenceTableFilter from './ReferenceTableFilter.vue';
import copyMixin from '../mixins/copyMixin';
import formatMixin from '../mixins/formatMixin';

import { BPagination, BInputGroup, BInputGroupAppend, BTable, BFormSelect, BFormCheckboxGroup } from '@iebh/bootstrap-vue';
Vue.component('b-pagination', BPagination)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-table', BTable)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)

export default {
	name: "ReferenceTable",
	components: {
		HighlightText,
		ReferenceTableFilter
	},
	mixins: [
		copyMixin,
		formatMixin
	],
	data() {
		return {
			rowsPerPage: 50,
			isShow: false,
			isFiltering: false,
			filterText: "",
			selectedRows: [],
			currentPage: 1,
			tableHeight: `${window.innerHeight - 220}px`,
			tableBusy: false,
			// Stores array of filtered ref IDs
			filteredRefIds: null,
			// The scroll position of the user on the page
			scrollValues: {
				all: 0,
				undecided: 0
			},
			// The current page number for paginationValues
			paginationValues: {
				all: 1,
				undecided: 1
			}
		}
	},
	computed: {
		...mapState('groups', ['selectedGroup']),
		...mapGetters('hotkeys', ['getGroupFromKeyCode']),
		...mapGetters('references', ['getRefsArray']),
		...mapGetters('groups', ['getSelectedGroupRefIds', 'customGroupsandUndecided', 'customGroupsAsMap', 'getGroup']),
		...mapGetters('tableFields', ['getFieldsActive']),
		items: function() {
			console.log("INFO: Retrieving references")
			return isShow => {
				if (this.filteredRefIds) {
					return this.getRefsArray(this.getSelectedGroupRefIds.filter(id => this.filteredRefIds.has(id)))
						.map(ref => {
							return {
								...ref,
								_showDetails: isShow
							}
						})
				} else {
					return this.getRefsArray(this.getSelectedGroupRefIds)
						.map(ref => {
							return {
								...ref,
								_showDetails: isShow
							}
						})
				}
			}
		},
		rows: function() {
			return this.items(this.isShow).length;
		}
	},
	mounted() {
		// Listen for hide/show abstract
		this.$root.$on('show-abstract', data => this.showAbstract(data));
		this.$root.$on('edit-reference', () => this.editReference());
		this.$root.$on('remove-reference-group', () => this.addSelectedRefsToGroup("undecided"));
		// Listen for reference commands
		this.$root.$on('show-single-abstract', () => this.showSingleAbstract());
		this.$root.$on('copy-title-text', () => this.copyTitleText());
		this.$root.$on('search-database', database => this.searchDatabase(database));
		// Monkey patch to prevent the default behavior of space and ctrl + A
		const keydown = this.$refs.table.onTbodyRowKeydown;
		if (!keydown) throw("Not a function, bootstrap-vue maybe updated: Try to monkey patch function again");
		this.$refs.table.onTbodyRowKeydown = function(e) {
			if (e.keyCode === 32) {
				e.preventDefault()
				return;
			}
			if (e.keyCode === 65) {
				e.preventDefault()
				return;
			}
			keydown(e);
		}

		this.initializeResizableColumns();

		// Listener for table height
		window.addEventListener('resize', () => {
			this.tableHeight = `${window.innerHeight - 220}px`;
		})
	},
	watch: {
		selectedGroup: function(newGroup, oldGroup) {
			const scrollTable = document.querySelector(".b-table-sticky-header");
			// Save old scroll and pagination value
			this.scrollValues[oldGroup] = scrollTable.scrollTop;
			this.paginationValues[oldGroup] = this.currentPage;
			// Set saved scroll and pagination value
			this.$nextTick(() => {
				if (!isNaN(this.scrollValues[newGroup])) {
					scrollTable.scrollTop = this.scrollValues[newGroup];
				}
				if (this.paginationValues[newGroup]) {
					this.currentPage = this.paginationValues[newGroup];
				}
			})
		},
		getFieldsActive: function() {
			this.initializeResizableColumns();
		}
	},
	methods: {
		onSelectRow(selection) {
			this.selectedRows = selection;
		},
		async selectNextItem() {
			// Get last selected index
			let i = this.$refs.table.selectedLastRow;
			// Clear selection
			this.$refs.table.clearSelected();
			await Vue.nextTick();
			// Select row
			this.$refs.table.selectRow(i);
			await Vue.nextTick();
			// Focus row
			this.$refs.table.$refs['item-rows'][i].$el.focus();
		},
		editReference(item) {
			if (item) {
				this.$root.$emit('show-edit', item);
			} else if (this.selectedRows[0]) {
				this.$root.$emit('show-edit', this.selectedRows[0]);
			} else {
				console.log("No reference found");
			}
		},
		keyPress(e) {
			// Select all
			if (e.code === "KeyA" && e.ctrlKey === true) {
				e.preventDefault();
				this.$refs.table.selectAllRows();
				return false;
			}
			else if (e.code === "ArrowDown" || e.code === "ArrowUp") {
				// Select item that is navigated to by arrow keys
				this.$refs.table.clearSelected();
				this.$refs.table.selectRow(parseInt(e.target.ariaRowIndex) - 1);
				return;
			}

			// Find hotkey function
			const action = this.getGroupFromKeyCode(e.code);
			if (action && /^g\d+/.test(action)) {
				// If action is assigning a group
				this.addSelectedRefsToGroup(action);
				return;
			} else if (action) {
				// Else is just an ordinary action (e.g. Show abstract)
				let match = /^(\S+)\s*(\((\S+)\))*/.exec(action);
				this.$root.$emit(match[1], match[3]);
				return;
			}
			// No hotkey exists
			console.log("INFO: Hotkey not valid:", e.code)
			return;
		},
		// Add selected references to group by key
		addSelectedRefsToGroup(groupKey) {
			if (this.selectedRows.length > 0) {
				this.addRefsToGroup(groupKey, this.selectedRows);
				this.selectNextItem();
			} else {
				alert("No rows selected");
			}
		},
		// Add array of refs to a group
		addRefsToGroup(newGroup, refs) {
			if (refs) {
				refs = refs.map(ref => {
					return {
						...ref,
						group: newGroup != "undecided" ? newGroup : null
					}
				});
				let refsObj = {};
				refs.forEach(ref => refsObj[ref.id] = ref);
				this.$store.dispatch('addRefsToGroup', {
					groupKey: newGroup,
					refs: refsObj
				});
			}
		},
		rowAttr(item, type) {
			if (!item.group || type !== "row" || !this.getGroup(item.group)) return;
			return { style: `background-color: ${this.getGroup(item.group).color};` };
		},
		searchDatabase(database) {
			if (this.selectedRows.length > 0) {
				var localUrl = '';
				var title = encodeURI(this.selectedRows[0].title);
				if (database === "bond-library") {
					localUrl = "https://librarysearch.bond.edu.au/discovery/search?query=any%2Ccontains%2C" + title + "&tab=Everything&search_scope=Everything&vid=61BOND_INST%3ABOND&offset=0";
				} else if (database === "pubmed") {
					localUrl = "https://www.ncbi.nlm.nih.gov/pubmed/?term=" + title;
				} else if (database === "google-scholar") {
					localUrl = "https://scholar.google.com/scholar?q=" + title;
				} else {
					console.error("Invalid database")
				}
				if (localUrl.length > 0) {
					window.open(localUrl, '_blank');
				}
			}
		},
		copyTitleText() {
			if (this.selectedRows.length > 0) {
				this.copyTextToClipboard(this.selectedRows[0].title)
			}
		},
		showSingleAbstract() {
			if (this.selectedRows.length > 0) {
				this.selectedRows.map(row => row._showDetails = !row._showDetails);
			}
		},
		showAbstract(isShow) {
			if (typeof(isShow) != "undefined") {
				console.log("H")
				this.isShow = !this.isShow;
				return;
			}
			this.isShow = isShow;
		},
		initializeResizableColumns() {
			var self = this;
			var thElm;
			var startOffset;
			var zindex = 99;
			// Cleanup existing divs
			[...document.getElementsByClassName("resize")].map(n => n && n.remove());
			// Add draggable divs
			Array.prototype.forEach.call(
				document.querySelectorAll("table th"),
				function (th) {
					var grip = document.createElement('div');
					grip.className = "resize"
					grip.innerHTML = "&nbsp;";
					grip.style.top = 0;
					grip.style.bottom = 0;
					grip.style.right = "-5px";
					grip.style.width = '10px';
					grip.style.position = 'absolute';
					th.style.zIndex = zindex;
					zindex -= 1;
					grip.style.cursor = 'col-resize';
					grip.addEventListener('mousedown', function (e) {
						e.preventDefault();
						e.stopPropagation();
						e.stopImmediatePropagation();
						// Set table busy to prevent sorting when resizing
						self.tableBusy = true;
						thElm = th;
						startOffset = th.offsetWidth - e.pageX;
						return false;
					});
					document.addEventListener('mouseup', function () {
						// Set table busy false shortly after mouseup
						setTimeout(() => self.tableBusy = false, 10);
					});

					th.appendChild(grip);
				});

			document.addEventListener('mousemove', function (e) {
				if (thElm) {
					thElm.style.width = startOffset + e.pageX + 'px';
				}
			}, { passive: true });

			document.addEventListener('mouseup', function () {
				thElm = undefined;
			});
		}
	}
}
</script>

<style>
.button {
	margin: 10px;
}
.light-table{
	max-height: calc(100vh - 160px) !important;
}
</style>