const getDefaultFieldsActive = () => {
	return [
		{
			key: "group"
		},
		{
			key: "year",
			sortable: true
		},
		{
			key: "title",
			sortable: true
		},
		{
			key: "authors",
			sortable: true
		},
		{
			key: "abstract"
		}
	];
}

const getDefaultFieldsInactive = () => {
	return [
		{
			key: "keywords",
			sortable: true
		},
		{
			key: "urls",
			sortable: true
		},
		{
			key: "notes",
			sortable: true
		},
		{
			key: "researchNotes",
			sortable: true
		},
		{
			key: "journal",
			sortable: true
		},
		{
			key: "date",
			sortable: true
		},
		{
			key: "pages",
			sortable: true
		},
		{
			key: "volume",
			sortable: true
		},
		{
			key: "number",
			sortable: true
		},
		{
			key: "isbn",
			sortable: true
		},
		{
			key: "label",
			sortable: true
		},
		{
			key: "caption",
			sortable: true
		},
		{
			key: "address",
			sortable: true
		}
	];
}

const state = {
	fieldsActive: getDefaultFieldsActive(),
	fieldsInactive: getDefaultFieldsInactive()
}

const getters = {
	getFieldsActive: state => state.fieldsActive,
	getFieldsInactive: state => state.fieldsInactive
}

const mutations = {
	setFieldsActive(state, fields) {
		state.fieldsActive = fields;
	},
	setFieldsInactive(state, fields) {
		state.fieldsInactive = fields;
	},
	reset(state) {
		state.fieldsActive = getDefaultFieldsActive();
		state.fieldsInactive = getDefaultFieldsInactive();
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}