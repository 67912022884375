import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	methods: {
		save() {

			const metadata = JSON.parse(JSON.stringify(this.$store.state.metadata.metadata));
			const refs = JSON.parse(JSON.stringify(this.$store.state.references.refs));
			const groupList = this.$store.getters['groups/getGroupListCopy'];
			const hotkeys = [...this.$store.state.hotkeys.hotkeys];
			const includeKeywords = [...this.$store.state.keywords.include];
			const excludeKeywords = [...this.$store.state.keywords.exclude];

			const saveObject = {
				metadata,
				refs,
				groupList,
				hotkeys,
				includeKeywords,
				excludeKeywords
			}

			const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(saveObject));
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute("href", dataStr);
			downloadAnchorNode.setAttribute(
				"download",
				"scrn_"
					+ this.getProjectName()
					+ " "
					+ this.getDateString()
					+ ".scrn"
			);
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	}
}